<template>
  <div>
    <div class="card p-3 shadow-sm">
      <form @submit.prevent="">
        <div class="row justify-content-between">
          <div class="col-auto">
            <h5 class="e-text-red">
              Inspection
            </h5>
          </div>
          <div class="col-auto"
               v-if="isAllowed('psc.main.inspection.details.edit') && isOwnVessel('pscReport')">
            <template v-if="toEdit">
              <button class="e-btn e-btn-outlined-red ml-2" type="button" @click="cancelUpdate" v-if="toEdit">Cancel</button>
              <button class="e-btn e-btn-blue ml-2" type="button" @click="updatePsc">Save</button>
            </template>
            <template v-else>
              <button class="e-btn e-btn-green ml-2 px-3" type="button" @click="editMainReport">Edit Inspection Details</button>
            </template>
          </div>
        </div>
        <table class="w-100">
          <tr>
            <td style="width: 25%">Name of Inspector <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td>
              <input type="text" v-if="toEdit" v-model="mainReport.inspection.inspector_name" class="form-control-sm form-control" name="inspection_inspector_name" id="inspection_inspector_name">
              <span v-else>{{ pscGet('inspection.inspector_name') | toUpper }}</span>
            </td>
          </tr>
          <tr>
            <td>Deficiencies <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td>
              <template v-if="!!mainReport.inspection && toEdit">
                <select class="form-control form-control-sm" v-model="mainReport.inspection.deficiencies" style="width: 150px;">
                  <option :value="true">YES</option>
                  <option :disabled="pscGet('psc_deficiencies').length > 0" :value="false">NO</option>
                </select>
              </template>
              <span v-else>{{pscGet('inspection.deficiencies') ? 'YES' : 'NO'}}</span>
            </td>
          </tr>
          <template v-if="!!mainReport.inspection">
            <tr v-if="!mainReport.inspection.deficiencies && toEdit">
              <td>Psc Bonus (USD)</td>
              <td>
                <input type="number" v-model="mainReport.inspection.bounty"
                       class="form-control form-control-sm" name="bounty"
                       id="bounty" step="0.01" min="0.00" style="width: 150px;">
              </td>
            </tr>
          </template>
          <template v-else>
            <tr v-if="!pscGet('inspection.deficiencies') && !toEdit">
              <td>Psc Bonus (USD)</td>
              <td>
                <span>{{ pscGet('inspection.bounty')}}</span>
              </td>
            </tr>
          </template>
          <tr>
            <td style="width: 25%">Detained <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td>
              <select v-if="toEdit" class="form-control form-control-sm" v-model="mainReport.inspection.detained" style="width: 150px;">
                <option :value="true">YES</option>
                <option :value="false">NO</option>
              </select>
              <span v-else>{{ pscGet('inspection.detained') | toYesOrNo | toUpper }}</span>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">Number of Deficiencies</td>
            <td>
              <span>{{ pscGet('psc_deficiencies').length }}</span>
            </td>
          </tr>
          <tr>
            <td style="width: 25%" class="align-top">Inspection Outline <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td>
              <vue-editor v-if="toEdit" v-model="mainReport.inspection.outline" :editor-toolbar="toolbar" ></vue-editor>
              <div v-else id="textArea" class="pre-wrap-txt textarea-display-card my-2" v-html="pscGet('inspection.outline')"/>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">Attached PSC Report <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td>
              <template v-if="toEdit">
                <template v-if="!addAttachment">
                  <button class="e-btn e-btn-green mr-3"
                          @click="addAttachment=!addAttachment"
                          v-if="isAllowed('psc.main.inspection.attachments.create') && isOwnVessel('pscReport') && !pscAttachments.length "
                  >
                    Add Attachment
                  </button>
                  <button class="e-btn e-btn-blue"
                          @click="downloadAll(1,pscReport,'inspection.id')"
                          v-if="isAllowed('psc.main.inspection.attachments.download-all') && isOwnVessel('pscReport') && pscAttachments.length"
                  >
                    Download All
                  </button>
                </template>
                <div v-else>
                  <input type="file" class="w-75" ref="inspectionAttachments" multiple
                         @change="addAttachmentToInspection">
                  <button class="e-btn e-btn-outlined-red" @click="cancelUpload">Cancel</button>
                </div>
              </template>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <psc-attachment :attachments="pscAttachments"
                              :to-edit="toEdit"
                              style="height: 25vh;"
                              :type="1"
                              view-permission="psc.main.inspection.attachments.view-download"
                              download-permssion="psc.main.inspection.attachments.view-download"
                              delete-permission="psc.main.inspection.attachments.delete"
                              @deleteEmitter="deleteInspectionAttachments"

              >
              </psc-attachment>
            </td>
          </tr>
        </table>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ObjectMixin from "../../../mixins/ObjectMixin";
import {AlertService} from "../../../services/AlertService";
import AttachmentMixin from "../../../mixins/attachmentMixin";
import {permissionMixin} from "@/mixins/permissionMixins";
import PscAttachment from "./PscAttachment.vue";

export default {
  name: "PscInspection",
  data() {
    return {
      toEdit: false,
      toolbar:[
        ['bold','italic','strike','underline'],
        [{'color':["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]}]
      ],
      default: {
        mainReport: {
          id: parseInt(this.$route.params.id),
          inspection: {
            inspector_name: null,
            deficiencies: false,
            detained: false,
            deficiency_count: 0,
            outline: null,
            bounty:0,
          },
        },
      },
      mainReport: {},
      rankIdMaster: 1,
      rankIdCE: 2,
      crewLists: {},
      addAttachment:false,
    }
  },
  mixins: [ObjectMixin,AttachmentMixin,permissionMixin],
  components:{PscAttachment},
  methods: {
    ...mapActions(['updatePscInfo','uploadPscAttachments']),
    async cancelUpdate(){
      if(await AlertService.cancelAlert()){
        this.resetFields()
      }
    },
    async updatePsc() {
      if (await AlertService.confirmUpdateAlert("Do you want to update ? ")) {
        const response = await this.updatePscInfo(this.mainReport);
        if (response) {
          AlertService.successAlert('PSC updated successfully', 'UPDATE PSC')
          this.resetFields()
        }
      }
    },
    editMainReport() {
      this.toEdit = true;
      this.mainReport = {
        id: this.pscGet('id'),
        inspection: {
          inspector_name: this.pscGet('inspection.inspector_name'),
          deficiencies: !!this.pscGet('inspection.deficiencies'),
          detained: !!this.pscGet('inspection.detained'),
          deficiency_count: this.pscGet('inspection.deficiency_count'),
          outline: this.pscGet('inspection.outline'),
          bounty: this.pscGet('inspection.bounty'),
        }
      }
    },
    resetFields() {
      this.toEdit = false
      this.mainReport = this.default.mainReport
    },

    async addAttachmentToInspection(){
      let formData=new FormData();

      if(this.$refs.inspectionAttachments.files.length){
        Array.prototype.forEach.call(this.$refs.inspectionAttachments.files,file=>{
          formData.append('attachments[]',file)
        })
      }
      formData.append('id',this.pscGet('id'))
      formData.append('type[id]',this.pscGet('inspection.id'))
      formData.append('type[type_id]',1)
      const response =await this.uploadPscAttachments(formData);
      if(response){
        AlertService.successAlert('Attachment added successfully','Add Inspection Attachments')
        this.addAttachment=false;
        await this.getAttachments(1,this.pscReport,'inspection.id')
      }
    },

    async cancelUpload() {
      if(await AlertService.cancelAlert()){
        this.$refs.inspectionAttachments.value=null;
        this.addAttachment=false;
      }
    },

    async deleteInspectionAttachments(attachment){
      const file=attachment.attachment
      const type=attachment.type
      const response = await this.deleteFilePsc(file,type)
      if(response){
        AlertService.successAlert('Psc Inspection Attachment deleted successfully','DELETE INSPECTION ATTACHMENT')
        await this.getAttachments(type,this.pscReport,'inspection.id')
      }
    }

  },
  computed: {
    ...mapGetters([
      'pscReport',
      'pscAttachments'
    ]),
  },
  watch : {
    toEdit () {
      this.$store.commit('SET_TO_EDIT', this.toEdit);
    }
  }

}
</script>

<style scoped>
table td {
  padding: 2px !important;
}
::v-deep .pre-wrap-txt.textarea-display-card p {
  margin-bottom: 0 !important;
}
</style>
